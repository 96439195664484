import React, { useState, useEffect } from 'react'
import {
  Typography,
  CircularProgress,
  Box,
  Radio,
  Divider,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { ForetagProperties, UserProfile } from 'App.types'
import { refreshUserProfile } from '@trr/app-shell-communication'
import { useAuthentication } from '@trr/app-shell-data'
import { useLazySetCompanyQuery } from 'services/user'

import CompanyContactInfo from '../CompanyContactInfo'

interface Props {
  single: boolean
  isFirstItemInList: boolean
  user: UserProfile
  company: ForetagProperties
  apphellFetchingUserProfile: boolean
  setApphellFetchingUserProfile: (fetchingUser: boolean) => void
}

const Company = ({
  single,
  isFirstItemInList,
  user: { email, mobilePhone, mdid },
  company,
  apphellFetchingUserProfile,
  setApphellFetchingUserProfile,
}: Props) => {
  const { sub } = useAuthentication()
  const [triggerSetCompany] = useLazySetCompanyQuery()

  const [showSpinner, setShowSpinner] = useState(false)

  const selectedCompany = mdid === sub

  const handleChangeCompany = () => {
    const triggerAndRefreshUserProfile = async () => {
      setShowSpinner(true)
      setApphellFetchingUserProfile(true)
      await triggerSetCompany(mdid)
      refreshUserProfile()
    }
    void triggerAndRefreshUserProfile()
  }

  useEffect(() => {
    setShowSpinner(false)
  }, [sub])

  return (
    <Box>
      <Box display="flex">
        {!single && (
          <Box>
            {apphellFetchingUserProfile && showSpinner ? (
              <Box sx={{ padding: 1 }}>
                <CircularProgress
                  size={24}
                  color="secondary"
                  data-testid="loader"
                />
              </Box>
            ) : (
              <Radio
                role="radio"
                name={company.name}
                inputProps={
                  {
                    'aria-label': `${company.name ?? ''}`,
                    'data-testid': 'change-company-button',
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
                value={company.id}
                color="secondary"
                checked={selectedCompany}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onClick={handleChangeCompany}
                disabled={apphellFetchingUserProfile && !selectedCompany}
              />
            )}
          </Box>
        )}
        <Box mt={1 / 2} ml={1} width="100%">
          <Typography variant="h5">{company.name}</Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ ...(isFirstItemInList && { pb: 2 }) }}
          >
            {company.organizationNumber}
          </Typography>
          {!isFirstItemInList && (
            <Divider sx={{ pl: { xs: 0, md: 6 }, pt: 2, mb: 5 }} />
          )}
        </Box>
      </Box>
      <Box>
        {(selectedCompany || single) && (
          <Box pl={{ xs: 0, md: 6 }} mb={6}>
            <CompanyContactInfo email={email} mobilePhone={mobilePhone} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Company
