import React from 'react'
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import Email from '@mui/icons-material/Email'
import Smartphone from '@mui/icons-material/Smartphone'
import { useDevice, useEpiContent } from '@trr/app-shell-data'
import { Content } from 'App.types'

type Props = {
  email: string | null
  mobilePhone: string | null
}

const CompanyContactInfo = ({ email, mobilePhone }: Props): JSX.Element => {
  const {
    foretagSettings: {
      editButtonText,
      emailLabel,
      phoneLabel,
      missingNumberLabel,
    },
    foretagSettingsLinks: { changeEmailLink, changePhoneLink },
  } = useEpiContent<Content>()
  const device = useDevice()
  const isMobile = device?.isMobile
  const emailPrimary = isMobile ? (
    <ListItemIcon sx={{ minWidth: '42px', maxWidth: '42px' }}>
      <Email />
    </ListItemIcon>
  ) : (
    emailLabel
  )
  const phonePrimary = isMobile ? (
    <ListItemIcon sx={{ minWidth: '42px', maxWidth: '42px' }}>
      <Smartphone />
    </ListItemIcon>
  ) : (
    phoneLabel
  )
  return (
    <List>
      <ListItem
        key="email"
        sx={{
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          pl: 1,
        }}
      >
        <ListItemText
          sx={{
            position: 'relative',
            display: { xs: 'flex', md: 'block' },
          }}
          primaryTypographyProps={{ color: 'text.secondary' }}
          primary={emailPrimary}
          secondary={
            <Typography
              noWrap
              sx={{
                position: { xs: 'unset', md: 'absolute' },
                top: 0,
                left: '200px',
              }}
              variant="body1"
              color="text.primary"
            >
              {email ?? ''}
            </Typography>
          }
        />
        <Button size="small" variant="text" href={changeEmailLink}>
          {editButtonText}
        </Button>
      </ListItem>
      <ListItem
        key="phone"
        sx={{
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          pl: 1,
        }}
      >
        <ListItemText
          sx={{
            position: 'relative',
            display: { xs: 'flex', md: 'block' },
          }}
          primaryTypographyProps={{ color: 'text.secondary' }}
          primary={phonePrimary}
          secondary={
            <Typography
              sx={{
                position: { xs: 'unset', md: 'absolute' },
                top: 0,
                left: '200px',
              }}
              variant="body1"
              color={mobilePhone ? 'text.primary' : 'text.secondary'}
            >
              {mobilePhone ?? missingNumberLabel}
            </Typography>
          }
        />
        <Button size="small" variant="text" href={changePhoneLink}>
          {editButtonText}
        </Button>
      </ListItem>
    </List>
  )
}
export default CompanyContactInfo
