import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from 'config/getConfig'
import { prepareHeaders } from 'config/prepareHeaders'

const { PUBLIC_WEB_API_URL } = getConfig()

// initialize an empty api service that we'll inject endpoints into later as needed
const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${PUBLIC_WEB_API_URL}`,
    prepareHeaders,
  }),
  tagTypes: ['Users', 'Memberships'],
  endpoints: () => ({}),
})

export default baseApi
