import { Box, Typography } from '@mui/material'
import React from 'react'

interface props {
  title: string
  subTitle: string
}

const Header = ({ title, subTitle }: props) => (
  <Box
    sx={{
      marginBottom: '3em',
    }}
  >
    <Typography component="h1" variant="h2">
      {title}
    </Typography>
    <Typography component="h2" variant="body1" color="text.secondary">
      {subTitle}
    </Typography>
  </Box>
)

export default Header
