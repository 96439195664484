import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from '@mui/material'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { Content, Result } from 'App.types'
import Header from 'components/Header'
import React, { useEffect, useState } from 'react'
import { searchApi } from 'services/search/search.api'
import isValidOrgNumber from 'utils/helpers'
import { useLazySetCompanyQuery, userApi } from 'services/user'
import { ErrorDialog } from 'components/ErrorDialog'
import { refreshUserProfile } from '@trr/app-shell-communication'

const AddCompany = () => {
  type views = 'initial' | 'companyFound'
  const { sub } = useAuthentication()
  const [orgNumber, setOrgNumber] = useState('')
  const [currentSub, setCurrentSub] = useState(sub)
  const [isValid, setIsValid] = useState(true)
  const [currentView, setCurrentView] = useState<views>('initial')
  const {
    addCompany: {
      heading,
      subtitle,
      stateOtherCompanyButtonLabel,
      orgNumberTitle,
      orgNumberInputLabel,
      makeNewSearchButtonLabel,
      companyConnectedText,
      companyNotConnectedText,
      notConnectedExplanation,
      verifyInformationText,
      cancelButtonLabel,
      validOrgNumberHint,
    },
    addCompanyLinks: { myCompaniesLink },
  } = useEpiContent<Content>()
  const [searchCompany, companies] =
    searchApi.useLazyMembershipByOrgNumberQuery()
  const [
    addCompany,
    { isSuccess: addCompanyIsSuccess, isError: addCompanyIsError },
  ] = userApi.useAddCompanyMutation()
  const [triggerSetCompany] = useLazySetCompanyQuery()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isValidOrgNumber(orgNumber)) {
      setIsValid(true)

      await searchCompany(orgNumber)
      setCurrentView('companyFound')
      return setOrgNumber('')
    }

    setIsValid(false)
  }

  const handleAddCompany = async (company: Result | null) => {
    if (company) {
      const { id: companyId, organizationNumber: orgNumber } = company
      setIsLoading(true)

      setOrgNumber('')

      const payload = await addCompany({
        companyId,
        orgNumber,
        workRole: [],
      })
        .unwrap()
        .catch(() => undefined)

      // Set newly added company as the selected
      payload && (await triggerSetCompany(payload))
      // Set refresh the userProfile
      refreshUserProfile()
    }
  }

  const handleReset = () => {
    setCurrentView('initial')
    setOrgNumber('')
  }

  useEffect(() => {
    if (addCompanyIsSuccess && sub !== currentSub) {
      setIsLoading(false)
      window.location.href = myCompaniesLink
      setCurrentSub(sub)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub, addCompanyIsSuccess])

  return (
    <>
      <Box>
        <Header title={heading} subTitle={subtitle} />
        <Box>
          {companies.isFetching || isLoading ? (
            <Box>
              <CircularProgress data-testid="loader" />
            </Box>
          ) : null}

          {currentView === 'companyFound' &&
            companies.data?.results?.length !== 0 &&
            !isLoading && (
              <Box data-testid="companyFound">
                <Typography variant="h5">
                  {companies?.data?.results[0].name ?? ''}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '16px' }}>
                  {companyConnectedText}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ marginBottom: '32px' }}
                >
                  {verifyInformationText}
                </Typography>

                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  columnGap={2}
                  rowGap={2}
                >
                  <Button
                    data-testid="add-company-button"
                    onClick={() =>
                      void handleAddCompany(
                        companies?.data?.results?.[0] ?? null
                      )
                    }
                  >
                    {heading}
                  </Button>

                  <Button
                    onClick={handleReset}
                    variant="outlined"
                    data-testid="state-other-company-button"
                  >
                    {stateOtherCompanyButtonLabel}
                  </Button>
                </Box>
              </Box>
            )}

          {currentView === 'companyFound' &&
            companies.data?.results.length === 0 && (
              <Box>
                <Typography variant="h5" sx={{ marginBottom: '16px' }}>
                  {companyNotConnectedText}
                </Typography>

                <Typography variant="body1" sx={{ marginBottom: '32px' }}>
                  {notConnectedExplanation}
                </Typography>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  columnGap={2}
                  rowGap={2}
                >
                  <Button data-testid="new-search-button" onClick={handleReset}>
                    {makeNewSearchButtonLabel}
                  </Button>

                  <Button href={myCompaniesLink} variant="outlined">
                    {cancelButtonLabel}
                  </Button>
                </Box>
              </Box>
            )}

          {currentView === 'initial' && !companies.isFetching && !isLoading ? (
            <Box>
              <form onSubmit={(e) => void handleSubmit(e)}>
                <FormControl sx={{ display: 'block' }} variant="standard">
                  <Box sx={{ marginBottom: '32px' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: '16px' }}
                    >
                      {orgNumberTitle}
                    </Typography>
                    <TextField
                      sx={{ width: { xs: '100%', sm: '400px' } }}
                      id="organisationsnummer"
                      label={orgNumberInputLabel}
                      variant="outlined"
                      value={orgNumber}
                      onChange={(e) => setOrgNumber(e.target.value)}
                      helperText={!isValid && validOrgNumberHint}
                      error={!isValid}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    columnGap={2}
                    rowGap={2}
                  >
                    <Button type="submit">Sök företag</Button>

                    <Button variant="outlined" href={myCompaniesLink}>
                      Avbryt
                    </Button>
                  </Box>
                </FormControl>
              </form>
            </Box>
          ) : null}
        </Box>
      </Box>
      <ErrorDialog type="searchCompanyError" open={companies.isError} />
      <ErrorDialog type="addCompanyError" open={addCompanyIsError} />
    </>
  )
}
export default AddCompany
