import React from 'react'
import App from 'App'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { ApiProvider } from '@reduxjs/toolkit/query/react'
import baseApi from 'services/baseApi'

const Index = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <ApiProvider api={baseApi}>
      <AppShellDataProvider value={appShellData}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AppShellDataProvider>
    </ApiProvider>
  )
}

export default Index
