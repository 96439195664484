import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Button } from '@mui/material'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { Content } from 'App.types'
import Header from 'components/Header'
import { useGetUserQuery } from 'services/user'
import useSortUserProfiles from 'utils/hooks/useSortUserProfiles'
import { ErrorDialog } from 'components/ErrorDialog'

import Company from './Company'

const MyCompanies = () => {
  const {
    foretagSettings: { heading, subtitle, addCompanyButtonText },
    foretagSettingsLinks: { addCompanyLink },
  } = useEpiContent<Content>()

  const {
    data: userProfiles = [],
    isLoading: getUserLoading,
    isError: getUserIsError,
  } = useGetUserQuery()

  const { sub } = useAuthentication()

  const [apphellFetchingUserProfile, setApphellFetchingUserProfile] =
    useState(false)

  const sortedUserProfiles = useSortUserProfiles(userProfiles)

  useEffect(() => {
    setApphellFetchingUserProfile(false)
    window.scrollTo(0, 0)
  }, [sub])

  return !getUserLoading && sortedUserProfiles ? (
    <>
      <Box>
        <Header title={heading} subTitle={subtitle} />

        <Box mb={6}>
          {sortedUserProfiles?.map((user, i) => (
            <Company
              key={user.kundProperties.foretag.id}
              isFirstItemInList={i === 0}
              single={userProfiles.length === 1}
              user={user}
              company={user.kundProperties.foretag}
              apphellFetchingUserProfile={apphellFetchingUserProfile}
              setApphellFetchingUserProfile={setApphellFetchingUserProfile}
            />
          ))}
        </Box>

        <Box>
          <Button href={addCompanyLink} variant="outlined">
            {addCompanyButtonText}
          </Button>
        </Box>
      </Box>
      <ErrorDialog type="getCompaniesError" open={getUserIsError} />
    </>
  ) : (
    <CircularProgress />
  )
}

export default MyCompanies
