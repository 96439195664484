import { useEpiContent } from '@trr/app-shell-data'
import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { Content } from 'App.types'

export type errorType =
  | 'searchCompanyError'
  | 'addCompanyError'
  | 'getCompaniesError'

type errorContent = Record<
  errorType,
  { heading: string; ingress: string; buttonText: string }
>

const ErrorDialog = ({ open, type }: { open: boolean; type: errorType }) => {
  const contentData = useEpiContent<Content>()
  const content: errorContent = {
    searchCompanyError: {
      heading: contentData?.addCompany?.errorSearchCompanyDialogHeading,
      ingress: contentData?.addCompany?.errorSearchCompanyIngress,
      buttonText: contentData?.addCompany?.errorSearchCompanyButtonText,
    },
    addCompanyError: {
      heading: contentData?.addCompany?.errorAddCompanyDialogHeading,
      ingress: contentData?.addCompany?.errorAddCompanyDialogIngress,
      buttonText: contentData?.addCompany?.errorAddCompanyDialogButtonText,
    },
    getCompaniesError: {
      heading: contentData?.foretagSettings?.errorGetCompaniesDialogHeading,
      ingress: contentData?.foretagSettings?.errorGetCompaniesDialogIngress,
      buttonText:
        contentData?.foretagSettings?.errorGetCompaniesDialogButtonText,
    },
  }

  const handleClickButton = () => {
    window.location.reload()
  }
  return (
    <Dialog
      data-testid={`${type}Dialog`}
      open={open}
      aria-labelledby={`${type}-heading`}
      aria-describedby={`${type}-ingress`}
    >
      <DialogTitle id={`${type}-heading`}>{content[type].heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${type}-heading`}>
          {content[type].ingress}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleClickButton}
          data-testid={`${type}DialogButton`}
        >
          {content[type].buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
