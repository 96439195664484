import { useAuthentication } from '@trr/app-shell-data'
import { UsersResponse } from 'App.types'
import { useMemo } from 'react'

const useSortUserProfiles = (userProfiles: UsersResponse): UsersResponse => {
  const { sub } = useAuthentication()

  const sortedUserProfiles = useMemo(() => {
    const sortedUserProfiles = userProfiles.slice()
    // Sort companies by matching user id
    sortedUserProfiles.sort((a, b) =>
      a.mdid === sub ? -1 : b.mdid === sub ? 1 : 0
    )
    return sortedUserProfiles
  }, [userProfiles, sub])

  return sortedUserProfiles
}

export default useSortUserProfiles
