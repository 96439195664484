import React from 'react'
import MyCompanies from 'pages/MyCompanies'
import AddCompany from 'pages/AddCompany'
import { useCurrentKey } from '@trr/app-shell-data'

const App = () => {
  const currentKey = useCurrentKey()

  return currentKey === 'mina-foretag' ? <MyCompanies /> : <AddCompany />
}

export default App
